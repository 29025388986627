function History() {
  return (
    <section>
      <div className="history">
        <div className="history__title">REACT</div>
        <div className="history__block">
          <div className="history__text">О разработке интернет-магазина:</div>

          <div className="history__container">
            <div className="history__about">
              <b>В данный момент реализовано:</b>
              <br/>- Проект работает на базе Platzi Fake Store API.
              <br/>- Лайки по карточкам покупок, можно посмотреть любимые покупки в отдельной вкладке (сердце).
              При повторном нажатии лайк удаляется.
              <br/>- Пагинация в каталоге товаров.
              <br/>- Выбор категории для показа определенного вида карточек покупок.
              <br/>- Добавление в корзину карточек покупок. Увеличение кол-ва покупок в каталоге товара одного вида (при
              повторном клике купить).
              <br/>- При переходе в корзину уменьшение и увеличение кол-ва товара одного вида, а также удаление любых
              товаров. Цена пересчитывается моментально. При покупке свыше определенной суммы добавляется текст о
              бесплатной доставке. Если корзина пустая - табличка с товарами заменяется текстом с просьбой добавить
              товар и соответсвующим графическим изображением.
              После нажатия "оформить заказ" в корзине происходит переход в форму ввода данных покупателя. В ней
              реализован предпросмотр покупаемых товаров и общая сумма заказа.
              <br/>- Работоспособная форма авторизации и регистрации пользователя.
            </div>
            <div className="history__about">
             <b> В процессе реализации:</b>
              <br/>- Валидация данных (реализована при регистрации).
              <br/>- Модальные окна при успешной регистрации и ошибках (Реализован попап при успешной регистрации).
              <br/>- Страница пользователя (Реализованы лайки, страница данных, возможность просмотра заказов
              отсутсвует).
              <br/>- Mобильная и планшетные версии.
              <br/>- Прочие возникающие в процессе работы проблемы ( Покупка со страницы пользователя, корректировка
              верстки и другое).
              <br/><b>Дальнейшее развитие:</b>
              <br/>- Добавление возможности редактирования данных пользователя.
              <br/>- Добавление учетной записи админа для возможности удаления и добавления товаров в каталоге, а также
              редактировния карточек (цен, названий).
              <br/>- etc.
            </div>
          </div>
        </div>


      </div>

    </section>
  )

}

export default History;
