import ButtonRainbow from "./ButtonRainbow";
import React from "react";

function PopupItem(props, {itemSelected, items, onClose}) {

  React.useEffect(() => {
    console.log(items)
  }, [items])
  return (
    < div className={`popup-item popup-item__image ${itemSelected>0 ? 'popup-item_opened' : ''}`}>
      <div className="popup-item__container">
        <button onClick={onClose} type="button" className="popup-item__close"></button>
        <div className='popup-item__title'> 122</div>
        <div className='popup-item__text'>
          666
        </div>
      </div>
    </div>
  )
}

export default PopupItem