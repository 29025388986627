import react from "../images/react.png";
import * as React from "react";
import {currentUserContext} from "../context/CurrentUserContext";


function ProfileMini() {
  const userInfo = React.useContext(currentUserContext)
  return (
    <section>
      <div className='profile-mini'>
        <img width="40px"
             src={react} alt="user"/>
        <div className='register-mini__box'>
          <div className='register-mini__title'>Добрый день, {userInfo.name}!
          </div>
          <div className='register-mini__text'>Приятных Вам покупок!</div>
        </div>
      </div>
    </section>
  )
}

export default ProfileMini