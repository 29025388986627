import user from "../images/userwhite.svg";
import ButtonRainbow from "./ButtonRainbow";
import ButtonGrey from "./ButtonGrey";
import {Link} from "react-router-dom";


function RegisterMini() {
  return (
    <section>
      <div className='register-mini'>
        <img width="40px"
             src={user} alt="user"/>
        <div className='register-mini__box'>
          <div className='register-mini__title'>Уже есть аккаунт или
            хотите зарегистрироваться ?
          </div>
          <div className='register-mini__text'>Войдите в кабинет и получите скидку</div>
        </div>

        <ButtonRainbow to='/signin' className='register-mini__button' label='Войти'/>

        <Link to="/signup">
          <ButtonGrey className='register-mini__button' label='Регистрация'/>
        </Link>
      </div>
    </section>
  )
}

export default RegisterMini