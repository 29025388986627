import './App.css';
import * as React from "react";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import MainLayout from "./MainLayout/MainLayout";
import News from "./pages/News";
import Cart from "./pages/Cart";
import Catalog from "./pages/Catalog";
import Main from "./pages/Main";
import 'bootstrap/dist/css/bootstrap.min.css'
import SaveLikesItems from "./components/SaveLikesItems";
import Profile from "./pages/Profile";
import {currentUserContext} from "./context/CurrentUserContext";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import Contacts from "./pages/Сontacts";
import Thanks from "./components/Thanks";
import Delivery from "./components/Delivery";
import Checkout from "./components/Checkout";
import {api} from "./utils/Api";
import {authApi} from "./utils/AuthApi";
import ProtectedRoute from "../src/components/ProtectedRoute";
import {useEffect} from "react";
import NotFound from "./pages/NotFound";
import PopupItem from "./components/PopupItem";


function App() {


  const [items, setItems] = React.useState([]);
  const [catalogItems, setCatalogItems] = React.useState([]);
  const [renderItems, setRenderItems] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [category, setCategory] = React.useState('');
  const [likes, setLikes] = React.useState([]);
  const [likesItems, setLikesItems] = React.useState([]);
  const [buyItems, setBuyItems] = React.useState([])
  const [addCartItems, setAddCartItems] = React.useState([]);
  const [countCartItems, setCountCartItems] = React.useState(1)
  const [total, setTotal] = React.useState(0)
  const [userInfo, setUserInfo] = React.useState({})
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [userData, setUserData] = React.useState(null);
  const [tokenChecked, setIsTokenChecked] = React.useState(false)
  const [token, setToken] = React.useState('')
  const [modalShow, setModalShow] = React.useState(false);
  const [itemSelected, setItemSelected] = React.useState(-1);

  const history = useNavigate()
  React.useEffect(() => {
    const jwt = localStorage.getItem('JWT')
    if (jwt) {
      setToken(jwt);
      setIsLoggedIn(true)
    }
  }, [])

  React.useEffect(() => {
    let tmp = buyItems;
    let sum = 0;
    tmp.forEach((a) => {
      sum = a.count * a.price + sum;
    })
    setTotal(sum)
  }, [buyItems, setBuyItems])


  React.useEffect(() => {
    if (token) {
      api.getProfile(token).then((data) => setUserInfo(data))
    }
  }, [token])

  const handleLogin = (username, password) => {
    return authApi
      .signin(username, password)
      .then((res) => {
        if (!res) {
          return;
        }
        setUserData({
          name: "",
          email: username
        });

        api.getProfile(res.access_token).then((data) => setUserInfo(data))
        localStorage.setItem('JWT', res.access_token);
        setIsLoggedIn(true)
        history('/profile')
      }).catch(console.log)
  }
  const handleSignUp = (name, email, password, avatar) => {
    return authApi
      .signup(name, email, password, avatar)
      .then((res) => {
        if (!res) {
          return;
        }
        setUserData({
          name: name,
          email: email,
          password: password,
          avatar: avatar,
        })
      })
      .then(() => {
        history('/signin');
        setModalShow(true);
      })

      .catch(console.log)
  }

  function handleItemClick(items) {
    setItemSelected(items)
  }

  function closePopup() {
    setItemSelected({})
  }

  return (
    <currentUserContext.Provider value={userInfo}>
      <div className="app">
        <Routes>
          <Route path='/' element={<MainLayout
            catalogItems={catalogItems}
            setCatalogItems={setCatalogItems}
            setCategory={setCategory}
            buyItems={buyItems}
            isLoggedIn={isLoggedIn}
          />}>
            <Route path='' element={<Catalog
              items={items}
              setItems={setItems}
              renderItems={renderItems}
              setRenderItems={setRenderItems}
              page={page}
              setPage={setPage}
              catalogItems={catalogItems}
              setCatalogItems={setCatalogItems}
              category={category}
              setCategory={setCategory}
              likes={likes}
              setLikes={setLikes}
              buyItems={buyItems}
              setBuyItems={setBuyItems}
              onItemClick={handleItemClick}
            />}/>
            <Route path='about' element={<Main/>}/>
            <Route path='news' element={<News/>}/>
            <Route path='cart' element={<Cart
              buyItems={buyItems}
              setBuyItems={setBuyItems}
              addCartItems={addCartItems}
              setAddCartItems={setAddCartItems}
              items={items}
              countCartItems={countCartItems}
              setCountCartItems={setCountCartItems}
              total={total}
              setTotal={setTotal}
            />}/>
            <Route path='contacts' element={<Contacts/>}/>
            <Route path='delivery' element={<Delivery/>}/>
            <Route path='likes' element={<SaveLikesItems
              likes={likes}
              setLikes={setLikes}
              renderItems={renderItems}
              setRenderItems={setRenderItems}
              likesItems={likesItems}
              setLikesItems={setLikesItems}
              buyItems={buyItems}
              setBuyItems={setBuyItems}
            />}/>
          </Route>
          <Route path='signin' element={<Login
            handleLogin={handleLogin}
            modalShow={modalShow}
            setModalShow={setModalShow}
          />}/>
          <Route path='signup' element={<Registration
            handleSignUp={handleSignUp}
          />}/>
          <Route path='thanks' element={<Thanks/>}/>
          <Route path='checkout' element={<Checkout
            total={total}
            addCartItems={addCartItems}
            buyItems={buyItems}
            isLoggedIn={isLoggedIn}
          />}/>
          <Route exact path="/profile"
                 element={
                   <ProtectedRoute isLoggedIn={isLoggedIn}>
                     <Profile
                       setToken={setToken}
                       likes={likes}
                       userData={userData}
                       setLikes={setLikes}
                       renderItems={renderItems}
                       setRenderItems={setRenderItems}
                       likesItems={likesItems}
                       setLikesItems={setLikesItems}
                       isLoggedIn={isLoggedIn}
                       setIsLoggedIn={setIsLoggedIn}
                       buyItems={buyItems}
                       setBuyItems={setBuyItems}
                     />
                   </ProtectedRoute>
                 }
          />
          <Route path="*" element={<NotFound/>}/>

        </Routes>
        <PopupItem items={items} temSelected={itemSelected} onClose={closePopup}/>
      </div>
    </currentUserContext.Provider>
  );
}

export default App;
