import * as React from "react";
import {Link, Navigate} from "react-router-dom";
import user from "../images/userwhite.svg";
import cart from "../images/cartwhite.svg";
import heart from "../images/heartwhite.svg";

import exit from "../images/exit.svg"
import SaveLikesItems from "../components/SaveLikesItems";
import Orders from "../components/Orders";
import ProfileData from "../components/ProfileData";
import {currentUserContext} from "../context/CurrentUserContext";
import SmallHeader from "../components/SmallHeader";
import SmallFooter from "../components/SmallFooter";
import ButtonRainbow from "../components/ButtonRainbow";
import OrdersEmpty from "../components/OrdersEmpty";

function Profile({
                   likes,
                   setLikes,
                   renderItems,
                   setRenderItems,
                   likesItems,
                   setLikesItems,
                   userData,
                   isLoggedIn,
                   setIsLoggedIn,
                   setToken,
                   buyItems,
                   setBuyItems,
                 }) {


  const [ordersBlock, setOrdersBlock] = React.useState(false)
  const [likesBlock, setLikesBlock] = React.useState(false)
  const [profileBlock, setProfileBlock] = React.useState(true)
  const userInfo = React.useContext(currentUserContext)

  React.useEffect(() => {
    console.log(userData)
  }, [])

  function setChangeButtons(number) {

    setProfileBlock(true)
    setLikesBlock(false)
    setOrdersBlock(false)
    if (number === 1) {
      setProfileBlock(true)
      setLikesBlock(false)
      setOrdersBlock(false)
    }
    if (number === 2) {
      setOrdersBlock(true)
      setProfileBlock(false)
      setLikesBlock(false)
    }
    if (number === 3) {
      setLikesBlock(true)
      setProfileBlock(false)
      setOrdersBlock(false)
    }
  }

  function handleLoggedOff() {
    if (isLoggedIn === true) {
      setIsLoggedIn(false);
      localStorage.removeItem("JWT");
      setToken('');
      return <Navigate to="/" replace/>
    }
  }


  return (
    <section>
      <SmallHeader/>
      <div className='profile__title'>Личный кабинет</div>
      <div className='profile'>
        <div className='profile__box'>

          <div className='profile__box-buttons'>
            <button className={`profile__box-button ${profileBlock ? 'profile__box-button_active' : ''}`}
                    onClick={() => setChangeButtons(1)}>
              <img width="14px"
                   src={user} alt="user"/>
              <div className='profile__text'> Детали профиля</div>
            </button>
            <button className={`profile__box-button ${ordersBlock ? 'profile__box-button_active' : ''}`}
                    onClick={() => setChangeButtons(2)}>
              <img width="18px"
                   src={cart} alt="cart"/>
              <div className='profile__text'>Заказы</div>
            </button>
            <button className={`profile__box-button ${likesBlock ? 'profile__box-button_active' : ''}`}
                    onClick={() => setChangeButtons(3)}>
              <img width="18px"
                   src={heart} alt="heart"/>
              <div className='profile__text'>Список желаемого</div>
            </button>

            <button onClick={handleLoggedOff} className='profile__link'>
              <div>
                <img width="18px"
                     src={exit} alt="exit"/>
              </div>
              <div>Выйти</div>
            </button>
          </div>
        </div>
        <div className='profile__buttons-items'>
          {profileBlock ? <ProfileData value={userInfo}/> : null}
          {likesBlock ?
            <SaveLikesItems likes={likes} setLikes={setLikes} renderItems={renderItems} setRenderItems={setRenderItems}
                            likesItems={likesItems} setLikesItems={setLikesItems}  buyItems={buyItems}
                            setBuyItems={setBuyItems}/> : null}
          {ordersBlock ? <OrdersEmpty/> : null}
        </div>

      </div>
      <SmallFooter/>
    </section>
  )
}

export default Profile