import React from "react";
import SmallHeader from "./SmallHeader";
import SmallFooter from "./SmallFooter";
import ButtonGrey from "./ButtonGrey";
import NumberBox from "./NumberBox";
import CartMini from "./CartMini";
import {Link} from "react-router-dom";
import ProfileMini from "./ProfileMini";
import RegisterMini from "./RegisterMini";
import ButtonRainbow from "./ButtonRainbow";


function Checkout({total, buyItems, addCartItems, isLoggedIn}) {

  return (
    <section>
      <SmallHeader/>


      <div className='checkout'>


        <div className='checkout__box-form'>
          <div className='checkout__button-title'>
            <ButtonRainbow label='Назад к покупкам' to='/' className='login__button-margin'/>

            <div className='checkout__title'>Оформить заказ</div>
          </div>
          {isLoggedIn === false ? <RegisterMini/> : <ProfileMini/>}

          <form>
            <div className='checkout__box'>

              <div className='checkout__data-title'>
                <NumberBox name='1'/>
                <div className='checkout__title'>Личные данные</div>
              </div>
              <div className='checkout__data'>
                <input className='checkout__input' placeholder='Имя'/>
                <input className='checkout__input' placeholder='Фамилия'/>
                <input className='checkout__input' placeholder='Телефон'/>
                <input className='checkout__input' placeholder='E-mail'/>
                <input className='checkout__input' placeholder='Страна'/>
                <input className='checkout__input' placeholder='Город'/>
              </div>
              <div className='checkout__data-title'>
                <NumberBox name='2'/>
                <div className='checkout__title'>Доставка</div>
              </div>
              <div className='checkout__box'>
                <legend className='checkout__title-legend'>Пожалуйста, выберите способ доставки:</legend>
                <div className='checkout__ratio-box'>
                  <input type="radio" id="delivery-1" name="delivery" value="Самовывоз" checked/>
                  <label htmlFor="delivery-1">Самовывоз</label>
                </div>
                <div className='checkout__ratio-box'>
                  <input type="radio" id="delivery-2" name="delivery" value="Доставка почтовой службой"/>
                  <label htmlFor="delivery-2">Доставка почтовой службой</label>
                </div>
                <div className='checkout__ratio-box'>
                  <input type="radio" id="delivery-3" name="delivery" value="Доставка курьером"/>
                  <label htmlFor="delivery-3">Доставка курьером</label>
                </div>

                <textarea className="checkout__textarea"
                          placeholder="Введите необходимые данные для доставки"></textarea>
              </div>
              <div className='checkout__data-title'>
                <NumberBox name='3'/>
                <div className='checkout__title'>Оплата</div>
              </div>
              <div className='checkout__box'>
                <div className='checkout__ratio-box'>
                  <input type="radio" id="pay-1" name="pay" value="Оплата при получении" checked/>
                  <label htmlFor="pay-1">Оплата при получении</label>
                </div>
                <div className='checkout__ratio-box'>
                  <input type="radio" id="pay-2" name="pay" value="Оплата картой" disabled/>
                  <label htmlFor="pay-2" disabled>Оплата картой (Не доступно в данный момент)</label>
                </div>
              </div>
            </div>
          </form>
        </div>
        <CartMini
          total={total}
          addCartItems={addCartItems}
          buyItems={buyItems}
        />
      </div>
      <SmallFooter/>
    </section>
  )
}

export default Checkout