import React from "react";
import headerLove from '../images/love.svg'
import headerUser from '../images/user.svg'
import headerCart from '../images/shopping-cart.svg'
import {Link} from "react-router-dom";
import Logo from "./Logo";


function Header({buyItems, isLoggedIn}) {

  return (
    <div className="header">
      <Logo/>
      <div className="header__menu">
        <Link to='/about' className="header__link">О нас</Link>
        <Link to='/' className="header__link">Каталог</Link>
        <Link to='/news' className="header__link">Новости</Link>
        <Link to='/delivery' className="header__link">Доставка</Link>
        <Link to='/contacts' className="header__link">Контакты</Link>
      </div>
      <div className="header__group">
        <Link to="/likes" className="header__item">
          <img width="30px" src={headerLove} alt="love"/>
        </Link>
        <Link to={(isLoggedIn === true) ? "/profile" : "/signin"} className="header__item">
          <img width="30" src={headerUser} alt="user"/>
        </Link>
        <Link to="/cart" className="header__item">
          <div className='header__count'>{buyItems.reduce(function (a, b) {
            return a + b.count;
          }, 0)}</div>
          <img width="30" src={headerCart} alt="cart"/>
        </Link>
      </div>
    </div>

  )
    ;
}

export default Header;
