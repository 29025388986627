import {Link} from "react-router-dom";
import SmallHeader from "../components/SmallHeader";
import SmallFooter from "../components/SmallFooter";
import ButtonRainbow from "../components/ButtonRainbow";
import React from "react";
import CenteredModal from "../components/CenteredModal";

function Login({handleLogin, modalShow, setModalShow}) {
  const [formValuesLogin, setFormValuesLogin] = React.useState({
    emailInput: '',
    passwordInput: ''
  });
  const handleInputChange = React.useCallback((e) => {
    const {name, value} = e.target;
    setFormValuesLogin(prevState => ({...prevState, [name]: value}));
  }, [setFormValuesLogin])

  const {emailInput, passwordInput} = formValuesLogin

  function signIn(e) {
    e.preventDefault();
    handleLogin(emailInput, passwordInput)
  }

  return (
    <section>
      <SmallHeader/>
      <CenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        buttonText={'Я понял(а)'}
        modalText={'Ваша регистрация прошла успешно. Теперь войдите под свои логином и паролем.'}
        modalTitle={'Уведомление о регистрации'}
      />
      <div className="login">
        <form onSubmit={signIn} id="form__input" name="loginInputForm" className="login__form">
          <div className="login__title">Авторизация</div>
          <p className="login__input-text">E-mail</p>
          {/*{!emailInputValid && <span className="popup__valid">Поле почты некорректно</span>}*/}
          <input value={emailInput} onChange={handleInputChange} id="email"
                 name="emailInput" type="text"
                 className="login__input "/>
          <p className="login__input-text">Пароль</p>
          {/*{!passwordInputValid && <span className="popup__valid">Поле пароля некорректно</span>}*/}
          <input value={passwordInput} onChange={handleInputChange} id="password"
                 name="passwordInput" type='password'
                 className="login__input"/>
          <button type="submit" className="login__button">Войти</button>

          <Link className='login__link' to="/signup">Еще не зарегистрированы? Регистрация</Link>
        </form>
      </div>
      <SmallFooter/>
    </section>
  )
}

export default Login