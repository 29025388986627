import headerLogo from "../images/react.png";
import ButtonRainbow from "./ButtonRainbow";
import {currentUserContext} from "../context/CurrentUserContext";
import * as React from "react";


function ProfileData() {
  const userInfo = React.useContext(currentUserContext)
  return (
    <section>
      <div className='profile__box-user'>
        <div className='catalog__title profile-title__margin'>Ваш профиль:</div>
        <form>
          <div className="profile__box-info">
            <img width="60px"
                 src={userInfo.avatar} alt="logo"/>
            <div className="profile__box-name">Добрый день, <b> {userInfo.name}</b></div>
          </div>
          <div className='profile__contain'>
            <div className='profile__contain-box'>
              <div className='profile__box-text'><b>Статус:</b></div>
              <div className='profile__box-text'><b>Ваш почтовый ящик:</b></div>
              <div className='profile__box-text'><b>Ваш пароль:</b></div>
            </div>
            <div className='profile__contain-box'>
              <input className='profile__input' value={userInfo.role} disabled></input>
              <input className='profile__input' value={userInfo.email}></input>
              <input className='profile__input' type="password" value={userInfo.password}></input>
            </div>
          </div>
          <div className='profile__contain-box'>
            <ButtonRainbow label={'Редактировать'}/>
          </div>
        </form>
      </div>
    </section>
  )
}

export default ProfileData




