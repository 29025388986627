import like from "../images/love.svg";
import SmallHeader from "../components/SmallHeader";
import SmallFooter from "../components/SmallFooter";
import sad from "../images/Sad.svg";


function NotFound() {
  return (
    <section>
      <SmallHeader/>
      <div className='not-found'>
        <h2 className='not-found__title about__sale-name'>Ошибка 404</h2>
        <p className='not-found__title catalog__title'>К сожалению данная страница не доступна.</p>
        <img width="200px"
             src={sad} alt="sad"/>
      </div>
      <SmallFooter/>
    </section>
  )
}

export default NotFound