import Logo from "./Logo";
import ButtonRainbow from "./ButtonRainbow";
import * as React from "react";


function SmallHeader() {
  return (
    <header className='small-header'>
      <Logo/>
      <ButtonRainbow to='/' label='Назад на главную' className='login__button-margin'/>
    </header>
  )
}

export default SmallHeader