import React from "react";
import line from "../images/Line 6.svg";
import ItemBlock from "../components/ItemBlock";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import PaginationBasic from "../components/PaginationBasic";
import {api} from "../utils/Api";
import CatalogItem from "../components/CatalogItem";


function Catalog({
                   items,
                   setItems,
                   renderItems,
                   setRenderItems,
                   page,
                   setPage,
                   catalogItems,
                   setCatalogItems,
                   setCategory,
                   category,
                   likes,
                   setLikes,
                   buyItems,
                   setBuyItems,
                   onItemClick
                 }) {


  React.useEffect(() => {
    api.getProducts(setItems, category)

  }, [category, setItems]);

  React.useEffect(() => {
    api.getCatalog(setCatalogItems)
  }, [setCatalogItems])

  React.useEffect(() => {
    const countItem = 9
    setRenderItems(items.slice(page * countItem, (page * countItem) + countItem))
  }, [items, page, setRenderItems])


  return (
    <section>
      <div className='catalog'>
        <Breadcrumb className="about__menu">
          <Breadcrumb.Item href="/">Главная</Breadcrumb.Item>
          <Breadcrumb.Item active>Каталог</Breadcrumb.Item>
        </Breadcrumb>
        <div className='catalog__title'>Категории:</div>
        <div className='catalog__box'>
          {catalogItems?.map((obj) => (
            <CatalogItem
              key={obj.id} {...obj}
              setCategory={setCategory}
            />
          ))}
        </div>
        <div className="catalog__line"></div>
        <div className='catalog__title'>Каталог продуктов:</div>
        <div className="catalog__box">
          {renderItems?.map((obj) => (
            <ItemBlock
              key={obj.id} {...obj}
              likes={likes}
              setLikes={setLikes}
              buyItems={buyItems}
              setBuyItems={setBuyItems}
              onItemClick={onItemClick}
              items={items}
            />
          ))}
        </div>
        <PaginationBasic setPage={setPage} page={page} items={items}/>
      </div>
    </section>
  )
}
export default Catalog