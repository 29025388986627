class AuthApi {

  signin(email, password) {
    return fetch(`https://api.escuelajs.co/api/v1/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: email,
        password: password
      })
    })
      .then(res => this._getResponseData(res))
      .catch(err => {
        console.log(err)
      })
  }

  _getResponseData(res) {
    if (!res.ok) {
      return Promise.reject(`Ошибка: ${res.status}`)
    }
    if (res.ok) {
      console.log(res.clone().json()); //first consume it in console.log
      return res.clone().json(); //then consume it again, the error happens

    }
    return res.clone().json();

  }

  signup(name, email, password, avatar) {
    return fetch(`https://api.escuelajs.co/api/v1/users/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name: name,
        email: email,
        password: password,
        avatar: avatar,
      })
    })
      .then((res) => {
        console.log()
        if (res.ok) {
          return res.json()
        }
      })
      .then(res => this._getResponseData(res))
      .catch(err => {
        console.log(err)
      })
  }


}

export const authApi = new AuthApi()