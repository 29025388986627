import React from "react";
import trash from "../images/can-trash.svg";
import imageFake from '../images/imageFake.png';

function ItemBlock({id, images, title, price, likes, setLikes, setBuyItems, buyItems,description, onItemClick}) {

  function handleClickLike(e) {
    e.preventDefault()
    if (likes.includes(id)) {
      //del likes
      let tmp = likes.filter((card) => (card !== id));
      setLikes(tmp);
    } else {
      //add likes, don't use push!!
      setLikes(() => [...likes, id]);
    }
  }

  function handleClickBuy() {
    let foundItem = buyItems.find(item => item.id === id)
    if (foundItem === undefined) {
      let newItem = {id: id, count: 1, price: price}
      setBuyItems(() => [...buyItems, newItem]);
    } else {
      let tmp = buyItems.filter(item => item.id !== id);
      foundItem.count++;
      tmp = [...tmp, foundItem]
      setBuyItems(tmp);
      console.log(buyItems.find(item => item.id === id).count)
    }
  }
  function handleClick() {
    onItemClick(id)
  }

  return (
    <section>
      <div className='itemBlock'>
        <button onClick={handleClick} className="itemBlock__cart">
          <div>
            <img className="itemBlock__img" width="300px"
                 src={images} alt="item"/>
          </div>
          <button className="itemBlock__delete" onClick={() => console.log('detele')}>
            <img width="30px"
                 src={trash} alt="delete"/>
          </button>
          <button
            className={(likes.includes(id)) ? 'itemBlock__like itemBlock__group-heart itemBlock__group-heart_active' : 'itemBlock__like itemBlock__group-heart '}
            onClick={handleClickLike}>

          </button>
        </button>
        <div className='itemBlock__box'>
          <div className='itemBlock__title'> {title}</div>
          <div className='itemBlock__price'>{price} $</div>
        </div>
        <button
          className={buyItems?.find(item => item.id === id)?.count > 0 ? "itemBlock__button itemBlock__button_active" : "itemBlock__button "}
          onClick={handleClickBuy}>{buyItems?.find(item => item.id === id)?.count > 0 ? `В корзине ${buyItems?.find(item => item.id === id)?.count} шт.` : "Добавить в корзину"}</button>

      </div>
    </section>
  )
}

export default ItemBlock