import React from "react";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import CartEmpty from "../components/CartEmpty";
import CartFull from "../components/CartFull";
import {currentUserContext} from "../context/CurrentUserContext";


function Cart({addCartItems, setAddCartItems, buyItems, setBuyItems, items, countCartItems, setCountCartItems, total}) {


  React.useEffect(() => {
    let temp = []

    items.forEach(item => {
      if (buyItems.find((found) => found.id === item.id) !== undefined) {
        temp.push(item)
      }
    })
    setAddCartItems(temp)
  }, [buyItems, items, setBuyItems])


  return (
    <section className='cart'>
      <Breadcrumb className="about__menu">
        <Breadcrumb.Item href="/">Главная</Breadcrumb.Item>
        <Breadcrumb.Item active>Корзина</Breadcrumb.Item>
      </Breadcrumb>
      <div className='cart__boxes'>
        {buyItems?.length > 0 ? <CartFull
          addCartItems={addCartItems}
          countCartItems={countCartItems}
          setCountCartItems={setCountCartItems}
          total={total}
          items={items}
          buyItems={buyItems}
          setAddCartItems={setAddCartItems}
          setBuyItems={setBuyItems}
        /> : <CartEmpty/>}
      </div>
    </section>
  )
}

export default Cart