import {Link} from "react-router-dom";
import ButtonGrey from "../components/ButtonGrey";
import SmallHeader from "../components/SmallHeader";
import SmallFooter from "../components/SmallFooter";
import React from "react";
import ButtonRainbow from "../components/ButtonRainbow";


function Registration({handleSignUp}) {

  const [formValuesRegister, setFormValuesRegister] = React.useState({
    nameInput: '',
    emailInput: '',
    passwordInput: '',
    avatarInput: '',
  });
  const [formValidityRegister, setFormValidityRegister] = React.useState({
    nameValid: false,
    emailValid: false,
    passwordValid: false,
    avatarValid: false,
  });
  const {nameInput, emailInput, passwordInput, avatarInput} = formValuesRegister;
  const {nameValid, emailValid, passwordValid, avatarValid} = formValidityRegister;
  const isSubmitDisabled = !nameValid || !emailValid || !passwordValid || !avatarValid;

  function signUp(e) {
    e.preventDefault();
    handleSignUp(nameInput, emailInput, passwordInput, avatarInput)
  }

  const handleInputChange = React.useCallback((e) => {
    const {name, value} = e.target;
    setFormValuesRegister(prevState => ({...prevState, [name]: value}));
  }, [setFormValuesRegister])
  React.useEffect(function validateInputs() {
    const isNameInputFilled = formValuesRegister.nameInput.length > 2
    const isNameInputValid = isNameInputFilled
    const emailTest = /\S+@\S+\.\S+/;
    const linkTest = /^((ftp|http|https):\/\/)?(www\.)?([A-Za-zА-Яа-я0-9]{1}[A-Za-zА-Яа-я0-9\-]*\.?)*\.{1}[A-Za-zА-Яа-я0-9-]{2,8}(\/([\w#!:.?+=&%@!\-\/])*)?/
    const isEmailInputFilled = formValuesRegister.emailInput.length > 4
    const isEmail = emailTest.test(formValuesRegister.emailInput)
    const isEmailInputValid = isEmailInputFilled && isEmail
    const isAvatarInputFilled = formValuesRegister.avatarInput.length > 4
    const isAvatar = linkTest.test(formValuesRegister.avatarInput)
    const isAvatarInputValid = isAvatarInputFilled && isAvatar

    const isPasswordInputFilled = formValuesRegister.passwordInput.length >= 3
    const isPasswordInputValid = isPasswordInputFilled
    setFormValidityRegister(prevValidity => ({
      nameValid: isNameInputValid,
      emailValid: isEmailInputValid,
      passwordValid: isPasswordInputValid,
      avatarValid: isAvatarInputValid
    }))

  }, [formValuesRegister, setFormValuesRegister])

  return (
    <section>
      <SmallHeader/>
      <div className="login">
        <form onSubmit={signUp} id="form__input" name="loginInputForm" className="login__form">
          <div className="login__title">Регистрация</div>
          <p className="login__input-text">Имя пользователя</p>
          <input value={nameInput} onChange={handleInputChange} id="name"
                 name="nameInput" type="text"
                 className="login__input "/>
          {!nameValid && <span className="login__valid">Имя слишком короткое</span>}
          <p className="login__input-text">E-mail</p>
          <input value={emailInput} onChange={handleInputChange} id="email"
                 name="emailInput" type="text"
                 className="login__input "/>
          {!emailValid && <span className="login__valid">Поле почты некорректно</span>}
          <p className="login__input-text">Ссылка на аватар</p>
          <input value={avatarInput} onChange={handleInputChange} id="avatar"
                 name="avatarInput" type="text"
                 className="login__input "/>
          {!avatarValid && <span className="login__valid">Ссылка на аватар некорректна</span>}
          <p className="login__input-text">Пароль</p>
          <input value={passwordInput} onChange={handleInputChange} id="password"
                 name="passwordInput" type='password'
                 className="login__input"/>
          {!passwordValid && <span className="login__valid">Поле пароля некорректно</span>}
          <button disabled={isSubmitDisabled} type="submit" className="login__button">Войти</button>

          <Link className='login__link' to="/signin">Уже зарегистрированы? Авторизация</Link>

        </form>

      </div>
      <SmallFooter/>
    </section>
  )
}

export default Registration