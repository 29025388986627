import React from "react";
import cart from "../images/cart-for-cart.svg";
import react from "../images/react.png"


function CartItemMini({images, title, price, id, buyItems}) {
  return (
    <section>
      <div className='cart-item-mini'>
        <div className='cart-item-mini__image'>
          <img width="60px" src={images} alt="cart-image"/>
        </div>

        <div className='cart-item-mini__name-box'>
          <div className='cart-item-mini__name'> {title}</div>
          <div className='cart-item-mini__box'>
            <div className='cart-item-mini__count'>{buyItems.find(item => item.id === id)?.count} шт.</div>
            <div className='cart-item-mini__total'>{price * (buyItems.find(item => item.id === id)?.count)} $</div>
          </div>
        </div>
      </div>

    </section>
  )
}

export default CartItemMini