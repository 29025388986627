import Breadcrumb from "react-bootstrap/Breadcrumb";
import line from "../images/Line 1.svg";
import flower from "../images/flower.svg";
import {Link} from "react-router-dom";


function Contacts() {
  return (
    <section>
      <div className="about">
        <div className="about__block">
          <Breadcrumb className="about__menu">
            <Breadcrumb.Item className='about__menu-links' href="/">Главная</Breadcrumb.Item>
            <Breadcrumb.Item className='about__menu-links' active>Контакты</Breadcrumb.Item>
          </Breadcrumb>


          <div className="about__name">Сайт в разработке</div>
          <div className="about__love">Сделано в качестве тренировки React</div>
          <div className="about__line">
            <img width="623px"
                 src={line} alt="line"/>
          </div>

          <div className="about__title"> Мои контакты:</div>
          <Link className="about__name" to='https://www.linkedin.com/in/olga-tabisheva-67541b258'>linkedin</Link>
          <Link className="about__name" to='https://github.com/OlgaTabisheva'>github</Link>


        </div>
        <div className="about__logo">
          <img width="200px"
               src={flower} alt="flower"/>
        </div>
      </div>
      <div className="about__box">
      </div>

    </section>
  )
}

export default Contacts