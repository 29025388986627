import {Link} from "react-router-dom";
import smallFooter from "../images/react.png";


function SmallFooter() {
  return (
    <footer className='small-footer'>
      <Link to='/'>
        <img src={smallFooter} width='60px' alt="Изображение логотипа"/>
      </Link>
      <div className='small-footer__text'> 2023 год</div>
      <div className='small-footer__text'>С заботой о пользователе</div>
    </footer>
  )
}

export default SmallFooter
