import line from "../images/Line 1.svg";
import CartItem from "./CartItem";
import CartEmpty from "./CartEmpty";
import car from "../images/car.svg";
import ButtonRainbow from "./ButtonRainbow";
import React from "react";


function CartFull({
                    buyItems,
                    addCartItems,
                    countCartItems,
                    setCountCartItems,
                    total,
                    items,
                    setAddCartItems,
                    setBuyItems
                  }) {
  return (
    <section className='cart-full'>
      <div className='cart__box'>
        <div className='cart__menu_visible'>
          <div className='cart__menu-item'>товар</div>
          <div className='cart__menu-item'>кол-во</div>
          <div className='cart__menu-item'>цена</div>
        </div>
        <div className="about__line">
          <img width="750px"
               src={line} alt="line"/>
        </div>
        {addCartItems?.map((obj) => (
          <CartItem
            key={obj.id} {...obj}
            countCartItems={countCartItems}
            setCountCartItems={setCountCartItems}
            items={items}
            addCartItems={addCartItems}
            setAddCartItems={setAddCartItems}
            buyItems={buyItems}
            setBuyItems={setBuyItems}

          />
        ))}
      </div>
      <div className='cart__container_visible '>
        <div className='container__box'>
          <div className='cart__menu-item'>Итого:</div>
          <div className='cart__menu-item'>{total}$</div>
        </div>
        <div
          className={total > 1500 ? 'cart__delivery-container cart__delivery-container_visible' : 'cart__delivery-container'}>
          <div className="container__car">
            <img width="48px"
                 src={car} alt="car"/>
          </div>
          <div className='cart__delivery'>У вас бесплатная доставка!</div>
        </div>
        <ButtonRainbow label={'Оформить заказ'} to='/checkout'></ButtonRainbow>
      </div>
    </section>
  );
}

export default CartFull;