import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";

function ButtonRainbow(props) {
  return (
    <div className="button-rainbow">
      <Link to={props.to}>
        <button type="button" className={`button-rainbow__button  ${props.className}`}>{props.label}</button>
      </Link>
    </div>
  )
}

export default ButtonRainbow