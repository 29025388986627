import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import {PaginationControl} from 'react-bootstrap-pagination-control';

function PaginationBasic({page, setPage, items}) {
  const max = items.length;
  return <PaginationControl
    page={page}
    between={4}
    total={max}
    limit={9}
    changePage={(page) => {
      setPage(page);
    }}
    ellipsis={1}
  />
}

export default PaginationBasic