import OrderItem from "./OrderItem";
import React from "react";
import sheet from "../images/sheet.svg";

function OrdersEmpty() {
  return (
    <section>
      <div className='ordersEmpty'>
        <h2 className='catalog__title profile-title__margin'>Здесь пока ничего не заказано <br/>(Страница заказов в
          разработке)</h2>

        <div className="ordersEmpty__image">
          <img width='200px' src={sheet} alt="sheet"/>
        </div>
      </div>
    </section>
  )
}

export default OrdersEmpty