import item from "../images/RGA.svg"
import line from "../images/Line 1.svg"
import sale from "../images/sale.svg"
import Breadcrumb from "react-bootstrap/Breadcrumb";

function About() {
  return (
    <section className='section'>
      <div className="about">
        <div className="about__block">
          <Breadcrumb className="about__menu">
            <Breadcrumb.Item href="/">Главная</Breadcrumb.Item>
            <Breadcrumb.Item active>О нас</Breadcrumb.Item>
          </Breadcrumb>
          <div className="about__title"> О нас</div>
          <div className="about__line">
            <img width="623px"
                 src={line} alt="line"/>
          </div>

          <div className="about__love">Сделано в качестве обучения React</div>

          <div className="about__container">
            <div className="about__name">Сайт в процессе разработки</div>
          </div>
        </div>
        <div className="about__logo">
          <img className="about__logo-image"
               src={item} alt="item"/>
        </div>
      </div>
      <div className="about__box">
        <div className="about__client-box">
          <div className="about__clients">Наши достоинства</div>
          <div className="about__us">Мы требовательны к себе, чтобы каждый день становится лучше для наших клиентов и
            находить лучшие решения для каждого из них
          </div>
        </div>
        <div className="about__sale">
          <div className="about__sale-name">Экономьте с нами</div>
          <div className="about__sale-number">
            <img className="about__sale-number-image"
                 src={sale} alt="sale"/>
          </div>
        </div>
      </div>

    </section>
  )
}

export default About