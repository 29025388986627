import bag from "../images/BagOK.png";

function DeliveryBlock(props) {
  return (
    <section>
      <div className='delivery-block'>
        <div className='delivery-block__date'>Дата: {props.data}</div>
        <img width="60px"
             src={bag} alt="bag"/>
        <div className='delivery-block__title'>{props.title}</div>
        <div className='delivery-block__text'>{props.text}</div>

      </div>
    </section>
  )
}

export default DeliveryBlock