import React from 'react';
import {Link} from 'react-router-dom';

import emptyBasket from '../images/shopping-basket.svg';
import ButtonRainbow from "./ButtonRainbow";

function CartEmpty() {

  return (
    <div className="cart cart-empty">
      <h2 className='catalog__title'>
        Корзина пустая
      </h2>
      <img className='cart-empty__image' src={emptyBasket} height='200px' alt="Empty cart"/>
      <p className='catalog__title'>
        Вероятней всего, вы не заказывали ничего.
        <br/>
        Для того, чтобы заказать, перейдите в каталог.
      </p>

      <ButtonRainbow to='/' label='В каталог' className='login__button-margin'/>
    </div>
  );
}

export default CartEmpty