import React from "react";
import cart from "../images/cart-for-cart.svg";
import imageFake from '../images/imageFake.png'

function CartItem({
                    images,
                    title,
                    buyItems,
                    setBuyItems,
                    price,
                    id
                  }) {


  function HandleClickCountPlus() {

    let foundItem = buyItems.find(item => item.id === id);
    if (foundItem.count <= 0) {

    } else {
      let tmp = buyItems.filter(item => item.id !== id);
      foundItem.count++;
      tmp = [...tmp, foundItem]
      setBuyItems(tmp);
    }
  }

  function HandleClickCountMinus() {
    let foundItem = buyItems.find(item => item.id === id);
    if (foundItem.count <= 1) {
      foundItem.count = 1
    } else {
      let tmp = buyItems.filter(item => item.id !== id);
      foundItem.count--;
      tmp = [...tmp, foundItem]
      setBuyItems(tmp);
    }
  }

  function HandleClickDeleteItem() {
    let filtered = buyItems.filter(tmp => tmp.id !== id)
    setBuyItems(filtered)
  }


  return (
    <section>
      <div className='cart-item'>
        <div className='cart-item__box-column cart-item__product'>
          <div className='cart-item__name'>
            <img width="60px" src={images[0]} alt="cart-image"/>
            {title}
          </div>
        </div>
        <div className='cart-item__box-column'>
          <div className='cart-item__buttons'>
            <button className='cart-item__button cart-item__price' onClick={HandleClickCountMinus}>-</button>
            <div className='cart-item__count'>{buyItems.find(item => item.id === id)?.count} шт.</div>
            <button className='cart-item__button ' onClick={HandleClickCountPlus}>+</button>
          </div>
        </div>
        <div className='cart-item__box-column cart-item__price'>
          <div className='cart-item__total'>{price * (buyItems.find(item => item.id === id)?.count)} $</div>
          <button className="cart-item__button-delete" onClick={HandleClickDeleteItem}>
            <img width="15.75px"
                 src={cart} alt="basket"/>
          </button>
        </div>
      </div>
    </section>
  )
}

export default CartItem