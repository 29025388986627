import like from "../images/love.svg";


function LikeItemsEmpty() {
  return (
    <section>
      <div className='like-items-empty'>
        <div className='like-items-empty__text'>Сначала поставь лайки на карточки в магазине!</div>
        <img width="150px"
             src={like} alt="like"/>
      </div>
    </section>
  )
}

export default LikeItemsEmpty