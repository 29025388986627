import headerLogo from "../images/react.png";


function Logo() {
  return (
    <div className="basic__logo">
      <img width="60px"
           src={headerLogo} alt="logo"/>
    </div>
  )
}

export default Logo