import History from "../components/History";
import DeliveryBlock from "./DeliveryBlock";


function Delivery() {
  return (
    <section>
      <div className='delivery'>
        <DeliveryBlock
          data={'Сегодня'}
          title={'Акция, бесплатная доставка!'}
          text={'Стартует акция, бесплатная доставка от 1500$, присоединяйтесь.'}
        />
        <DeliveryBlock
          data={'Вчера'}
          title={'Внимание, доставка!'}
          text={'В нашем магазине есть доставка курьером и доставка почтой - покупая экономьте время.'}
        />
      </div>
    </section>
  )
}

export default Delivery