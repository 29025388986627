import ItemBlock from "./ItemBlock";
import React, {useEffect} from "react";
import LikeItemsEmpty from "./LikeItemsEmty";


function SaveLikesItems({likes, setLikes, renderItems, setRenderItems, likesItems, setLikesItems, buyItems, setBuyItems}) {


  useEffect(() => {
    let temp = []
    renderItems.forEach(item => {
      if (likes.includes(item.id)) {
        temp.push(item)
      }

    })
    setLikesItems(temp)
  }, [likes, renderItems, setRenderItems, setLikesItems])

  return (
    <section>
      <div className='catalog-likes'>
        <div className='catalog__title profile-title__margin'>Ваши сохраненные продукты:</div>
        <div className="catalog-likes__box">
          {(likesItems.length !== 0) ? (likesItems?.map((obj) => (
            <ItemBlock
              key={obj.id} {...obj}
              likes={likes}
              setLikes={setLikes}
              buyItems={buyItems}
              setBuyItems={setBuyItems}
            />
          ))) : null}


        </div>
        {(likesItems.length !== 0) ? null : <LikeItemsEmpty/>}

      </div>
    </section>
  )
}

export default SaveLikesItems