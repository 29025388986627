import thanks from '../images/Ok.svg'
import ButtonRainbow from "./ButtonRainbow";
import SmallHeader from "./SmallHeader";
import SmallFooter from "./SmallFooter";


function Thanks() {
  return (
    <section>
      <SmallHeader/>
      <div className="thanks">
        <div className="thanks__logo">
          <img width="350px"
               src={thanks} alt="thanks"/>
          <div className="login__title">Спасибо за заказ</div>
          <div className="thanks__text">Мы свяжемся с вами в ближайшее время</div>
          <div className="thanks__text">Это учебный сайт, часть функционала может быть не доступна.</div>
          <ButtonRainbow to='/' label='Главная'/>

        </div>
      </div>
      <SmallFooter/>
    </section>
  )
}

export default Thanks