import React from "react";
import imageFake from '../images/imageFake.png'
import {Image} from "react-bootstrap";

function CatalogItem(props) {
  return (
    <section>
      <button className="catalog__item" onClick={() => props.setCategory(props.id)}>
        <img  className='catalog__image'
             src={props.image} alt="item"/>
        <div className='catalog__text'>{props.name}</div>

      </button>
    </section>
  )
}

export default CatalogItem