import React from "react";
import imageFake from '../images/imageFake.png'
import {Image} from "react-bootstrap";
import CartItemMini from "./CartItemMini";
import CartTotalMini from "./CartTotalMini";
import CartItem from "./CartItem";
import CartEmpty from "./CartEmpty";

function CartMini({total, addCartItems, buyItems}) {
  return (
    <section className='cart-mini'>
      <div className='cart-mini__box-items'>
        {buyItems?.length > 0 ? (addCartItems?.map((obj) => (
          <CartItemMini
            key={obj.id} {...obj}
            buyItems={buyItems}
          />
        ))) : <CartEmpty/>}

      </div>
      <CartTotalMini total={total}/>

    </section>
  )
}

export default CartMini