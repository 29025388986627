import React from "react";

import CartItemMini from "./CartItemMini";
import car from "../images/car.svg";
import ButtonRainbow from "./ButtonRainbow";
import {Link} from "react-router-dom";

function CartTotalMini({total}) {
  return (
    <section className='cart-total-mini'>

      <input placeholder="введите промокод"/>
      <button>применить</button>
      <div className="cart-total-mini__title">Итого</div>
      <div className="cart-total-mini__total">{total} $</div>
      <div
        className={total > 1500 ? 'cart__delivery-container cart__delivery-container_visible' : 'cart__delivery-container'}>
        <div className="cart-total-mini__car">
          <img width="48px"
               src={car} alt="car"/>
        </div>
        <div className='cart-total-mini__delivery'>У вас бесплатная доставка!</div>
      </div>

      <ButtonRainbow to='/thanks' label="Оформить заказ"></ButtonRainbow>
    </section>
  )
}

export default CartTotalMini