import About from "../components/About";
import History from "../components/History";
import Choice from "../components/Choice";

function Main() {
  return (
    <div className="main">
      <About/>
    </div>
  );
}

export default Main;