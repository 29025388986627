class Api {
  constructor({baseUrl}) {
    this._baseUrl = baseUrl
  }

  getProducts(setItems, category) {
    fetch(
      `${this._baseUrl}/products?categoryId=${category}`
    ).then((res) => res.json())
      .then((data) => {
        setItems(data)
      })
      .catch((error) => console.log(error))
  }

  getCatalog(setCatalogItems) {
    fetch(
      `${this._baseUrl}/categories`
    ).then((res) => res.json())
      .then((data) => {
        setCatalogItems(data.slice(0, 5))
      })
      .catch((error) => console.log(error))

  }

  getUser(id) {
    return fetch(`${this._baseUrl}/users/${id}`, {})
      .then(res => console.log(res))
  }


  getProfile(newToken) {
    return fetch(`${this._baseUrl}/auth/profile`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${newToken}`
      }
    }).then((res) => {
      if (res.ok) {
        return res.json()
      }
    }).then((data) => (data))
      .catch(err => console.log(err))
  }

}

export const api = new Api({
  baseUrl: 'https://api.escuelajs.co/api/v1',
});