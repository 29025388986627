import History from "../components/History";


function News() {
  return (
    <section>
      <div className='news'>
        <History/>
      </div>
    </section>
  )
}

export default News