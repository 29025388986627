import {Outlet} from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

function MainLayout(props) {
  return (
    <div className="main-layout">
      <Header
        buyItems={props.buyItems}
        isLoggedIn={props.isLoggedIn}
      />
      <div className="main-layout__content">

        <Outlet/>
      </div>
      <Footer
        catalogItems={props.catalogItems}
        setCatalogItems={props.setCatalogItems}
        setCategory={props.setCategory}
      />
    </div>
  );
}

export default MainLayout;