import twitter from '../images/twitter.svg'
import inta from '../images/inta.svg'
import facebook from '../images/facebook.svg'
import {Link} from "react-router-dom";
import Logo from "./Logo";


function Footer(props) {
  return (
    <div className="footer">
      <div className="footer__container">
        <Logo/>
        <div className="footer__rights">
          В учебных целях.
          Все права защищенны
        </div>
        <div className="footer__politics">
          Политика конфиденциальности
        </div>
      </div>
      <div className="footer__blocks">
        <div className="footer__block">
          <p className="footer__text">Навигация</p>
          <Link to='/about' className="footer__link">О нас</Link>
          <Link to='/' className="footer__link">Каталог</Link>
          <Link to='/news' className="footer__link">Новости</Link>
          <Link to='/delivery' className="footer__link">Доставка</Link>
          <Link to='/contacts' className="footer__link">Контакты</Link>
        </div>
        {/* <div className="footer__block">
          <p className="footer__text">Каталог</p>
          {props.catalogItems?.map((obj) => (
            <button  key={obj.id} {...obj} className="footer__button" onClick={()=>props.setCategory(props.id)}>{obj.name}</button>

          ))}


        </div>*/}
        <div className="footer__block">
          <p className="footer__text">Контакты</p>
          <p className="footer__contacts">Мы свяжемся с вами</p>
          <p className="footer__contacts">123 456 789</p>
          <p className="footer__contacts">12345@gmail.com</p>
          <div className='footer__social'>
            <Link to='https://twitter.com/' className="footer__item">
              <img width="20.8px" height="16.93px"
                   src={twitter} alt="twitter"/>
            </Link>
            <Link to='https://www.facebook.com/' className="footer__item">
              <img width="9.48px" height="17.58px"
                   src={facebook} alt="facebook"/>
            </Link>
            <Link to='https://www.instagram.com/' className="footer__item">
              <img width="16.06px" height="16.5px"
                   src={inta} alt="insta"/>
            </Link>
          </div>
        </div>
      </div>

    </div>

  )
    ;
}

export default Footer;
